<template>
    <section class="section video-grid-collection">
        <span class="anchor" id="video-grid-collection-anchor"></span>
        <div class="container">
            <slot name="header">
                <!-- <h2 class="section-title">
                    Explore all videos
                </h2> -->
            </slot>
            <div class="main-filter-wrapper dropdown" v-if="collectionFilter">
                <button @click="showMobileFilterFunction()" class="show-filter-btn"
                    :class="{ 'show-mobile-filter': showMobileFilter }">
                    Filter
                </button>
                <div class="inner-wrapper" :class="{ 'show-mobile-filter': showMobileFilter }">
                    <!-- <button v-if="displayResetBtn" class="reset-all-filters" @click="resetAllFilters()">Reset all</button> -->

                    <div class="filter-wrapper" v-for="filterType in catFilters" :key="filterType.name">
                        <select @change="onFilterChange" v-model="filterVal[filterType.name]">
                            <option selected="selected" value="*">
                                {{ getCorrectFilterLabel(filterType.name) }}
                            </option>
                            <option v-for="child in filterType.children" :key="child.id" :value="child.id">
                                {{ child.enumValue }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column-wrapper">
                <div class="column grid-collection">
                    <transition name="slow-fade">
                        <div class="items-wrapper" v-if="videoContentArray &&
                videoContentArray.length > 0
                ">
                            <VideoOverviewItem v-for="item in videoContentArray" :key="item.mediaId" :data="item"
                                :observer="observer" />
                        </div>
                    </transition>
                    <transition name="slow-fade">
                        <div class="message no-items-found" v-if="showNoItemsFound && !showLazyLoadSpinner">
                            No videos found..
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="lazyload-spinner" v-if="showLazyLoadSpinner">
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @import component
import VideoOverviewItem from "@/components/VideoOverviewItem";
import {
    fetchNestedEnumValues,
    fetchVideoContentList,
} from "@streampac.io/chef_sp_1";

export default {
    name: "VideoGridFilterCollection",
    inject: ["isAuthenticated"],
    components: {
        VideoOverviewItem,
    },
    data() {
        return {
            showMobileFilter: false,
            initialPageLoad: true,
            showNoItemsFound: false,
            loadedAllItemFound: false,
            observer: null,
            totalItems: 0,
            totalLoadedItems: 0,
            catFilters: [],
            filterVal: [],
            showLazyLoadSpinner: false,
            data: null,
            videoContentArray: [],
            fetchDataParams: {
                filter: {
                    searchTerm: "",
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 1,
                },
                flattenFields: true,
            },
            displayResetBtn: false,
        };
    },
    //props: ["ApiQueryParams", "collectionFilter", "paging"],
    props: {
        collectionFilter: {
            type: Object,
            required: true,
        },
        paging: {
            type: Object,
            required: true,
        },
        searchParams: {
            type: Object,
            required: false,
        },
        enumId: {
            type: String,
            required: false,
        },
        onlyShowFree: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            root: this.$el,
            threshold: 0.5,
        });

        if (this.paging) {
            this.fetchDataParams.paging.pageNumber = this.paging.pageNumber;
            this.fetchDataParams.paging.pageSize = this.paging.pageSize;
        }

        if (this.searchParams && this.searchParams !== "") {
            this.searchParams.forEach((item) => {
                this.filterVal[item.type] = item.value;
            });
        }

        if (this.collectionFilter) {
            this.collectionFilter.forEach((filterType) => {
                this.filterVal[filterType] = "*";
            });


            setTimeout(() => {
                fetchNestedEnumValues(this.isAuthenticated).then((response) => {
                    if (response && response.length > 0) {
                        response.forEach((name) => {
                            this.collectionFilter.forEach((filterType) => {

                                if (name.enumName === filterType) {
                                    let enumObj = {
                                        name: name.enumName,
                                        children: name.enumerationValues,
                                    };

                                    this.catFilters.push(enumObj);
                                }
                            });
                        });

                        //Sort order by order collectionFilter order.
                        this.catFilters.sort((a, b) => {
                            return this.collectionFilter.indexOf(a.name) - this.collectionFilter.indexOf(b.name);
                        });
                    }
                });
            }, 100);
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (queryString && queryString !== "") {
            for (const [key, value] of urlParams.entries()) {
                if (value && value !== "") {
                    this.filterVal[key] = value;
                }
            }
        }

        setTimeout(() => {
            //this.fetchVideoContentData();
            this.changeFetchParams(this.filterVal);

        }, 100);

        if (this.onlyShowFree && this.onlyShowFree === true) {
            this.fetchDataParams.filter.free = true;
        }

        //On load check if the reset button needs display
        this.checkDisplayResetFilterBtn();
    },
    mounted() {
        if (this.$route.hash == "#video-grid-collection-anchor") {
            setTimeout(() => {
                var anchor = document.querySelector(
                    "#video-grid-collection-anchor"
                );
                anchor.scrollIntoView({
                    behavior: "smooth",
                });
            }, 600);
        }
    },
    unmounted() {
        this.observer.unobserve(this.$el);
    },
    methods: {
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting) {
                    this.observer.unobserve(target);

                    this.totalItems = this.totalItems + 1;
                    this.totalLoadedItems = this.totalLoadedItems + 1;
                }
            });

            if (this.totalItems == this.fetchDataParams.paging.pageSize) {
                setTimeout(() => {
                    this.fetchDataParams.paging.pageNumber =
                        this.fetchDataParams.paging.pageNumber + 1;

                    this.fetchVideoContentData(
                        this.fetchDataParams.paging.pageNumber
                    );
                }, 50);

                //reset count to 0 again
                this.totalItems = 0;
            }
        },
        onFilterChange() {
            this.changeFetchParams(this.filterVal);
            this.totalItems = 0;
            this.totalLoadedItems = 0;

            this.checkDisplayResetFilterBtn();
        },
        fetchVideoContentData(val) {
            this.showLazyLoadSpinner = true;
            this.fetchDataParams.paging.pageNumber = val;

            if (val == undefined) this.fetchDataParams.paging.pageNumber = 1;

            let inputData = this.fetchDataParams;

            fetchVideoContentList(this.isAuthenticated, inputData, true).then(
                (response) => {
                    const paginationHeaders = JSON.parse(
                        response.headers["x-pagination"]
                    );

                    this.totalCount = paginationHeaders.TotalCount;

                    if (paginationHeaders.TotalCount == 0) {
                        this.showNoItemsFound = true;
                    }

                    if (paginationHeaders.TotalCount == this.totalLoadedItems) {
                        this.loadedAllItemFound = true;
                    }

                    //setTimeout(() => {
                    this.data = response.data;

                    this.processFetch();
                    //}, 100);

                    setTimeout(() => {
                        this.showLazyLoadSpinner = false;
                    }, 500);
                }
            );

            this.changeUrlParameters();
        },
        processFetch() {
            let videoContentArray = this.videoContentArray;

            if (this.data && this.data.length > 0) {
                this.data.forEach((item) => {
                    videoContentArray.push(item);
                });
            }
        },
        changeFetchParams(val) {
            //reset data to prepare for filtering
            this.data = null;
            this.videoContentArray = [];
            this.fetchDataParams.paging.pageNumber = 1;
            this.loadedAllItemFound = false;
            this.totalLoadedItems = 0;
            this.showLazyLoadSpinner = false;
            this.showNoItemsFound = false;

            const valValuesArray = Object.values(val);

            var filteredArray = valValuesArray.filter(function (e) {
                return e !== "*";
            });

            const stringifyVal = filteredArray.toString().replaceAll(",", "; ");

            //Correctly set searchTerm here
            this.fetchDataParams.filter.searchTerm = stringifyVal;

            //this.changeUrlParameters();

            //Fetch the content
            this.fetchVideoContentData(1);
        },
        changeUrlParameters() {
            //console.log(history);

            setTimeout(() => {
                if (this.searchParams && this.searchParams.length > 0) {
                    let filter = new URLSearchParams();

                    const pluck = (array, key) => {
                        return array.map((o) => o[key]);
                    };

                    const searchParamTypes = pluck(this.searchParams, "type");

                    for (const [key, value] of Object.entries(this.filterVal)) {
                        if (
                            // value &&
                            // value !== "" &&
                            // value !== "*" &&
                            !searchParamTypes.includes(key)
                        ) {
                            filter.set(key, value);
                        }
                    }

                    // if (this.initialPageLoad && history && history.state !== "") {
                    // 	history.replaceState(filter.toString(), "", "?" + filter);
                    // } else {
                    history.pushState(filter.toString(), "", "?" + filter);
                    //}
                } else {
                    let filter = new URLSearchParams();

                    for (const [key, value] of Object.entries(this.filterVal)) {
                        filter.set(key, value);
                    }

                    //if (this.initialPageLoad && history && history.state !== "") {
                    // 	history.replaceState(filter.toString(), "", "?" + filter);
                    // } else {
                    history.pushState(filter.toString(), "", "?" + filter);
                    //}

                    //this.initialPageLoad = false;
                }
            }, 200);
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(
                (key) => Object.values(object[key]).indexOf(value) > -1
            );
        },
        lookupTitle(val) {
            const getEnums = this.$store.state.getEnumLookup;

            if (getEnums && getEnums.length > 0) {
                const correctItem = getEnums.filter((item) => item.id === val);

                if (correctItem && correctItem.length > 0) {
                    return correctItem[0].enumValue;
                } else {
                    return val;
                }
            } else {
                return val;
            }
        },
        showMobileFilterFunction() {
            this.showMobileFilter = !this.showMobileFilter;
        },
        getCorrectFilterLabel(val) {
            if (val == 'Exercises') {
                return 'All exercises';
            } else if (val == 'Skills') {
                return 'All skills';
            } else if (val == 'Classes') {
                return 'All classes';
            } else {
                return val;
            }
        },
        resetAllFilters() {
            if (this.filterVal && this.filterVal !== '') {
                Object.keys(this.filterVal).forEach(key => {

                    if (this.collectionFilter && this.collectionFilter !== '' && this.collectionFilter.includes(key)) {
                        this.filterVal[key] = '*';
                    }

                });

                this.onFilterChange();
            }
        },
        checkDisplayResetFilterBtn() {
            let valsArray = [];

            //Go through each filter val
            Object.keys(this.filterVal).forEach(key => {
                if (this.collectionFilter && this.collectionFilter !== '' && this.collectionFilter.includes(key)) {
                    if (this.filterVal[key] !== '*') {
                        //if val equals a real value
                        valsArray.push(this.filterVal[key]);
                    }
                }
            });

            if (valsArray && valsArray.length > 0) {
                this.displayResetBtn = true;
            } else {
                this.displayResetBtn = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
// .video-grid-collection {
// 	.column.grid-collection {
// 		display: flex;
// 		flex-flow: row wrap;
// 		gap: 50px;
// 		.video-item {
// 			flex: 0 1 calc(33.33% - 33.33px);
// 		}
// 	}
// }</style>
